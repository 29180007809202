@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-ext-400-normal.5baf44ce.woff2") format("woff2"), url("roboto-cyrillic-ext-400-normal.a08b2a3e.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-400-normal.6d360dd5.woff2") format("woff2"), url("roboto-cyrillic-400-normal.a5a342c2.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-ext-400-normal.3b199b49.woff2") format("woff2"), url("roboto-greek-ext-400-normal.e526b0c1.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-400-normal.94705851.woff2") format("woff2"), url("roboto-greek-400-normal.8ad61867.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-vietnamese-400-normal.b7170924.woff2") format("woff2"), url("roboto-vietnamese-400-normal.80131064.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-ext-400-normal.65ba6c38.woff2") format("woff2"), url("roboto-latin-ext-400-normal.7013a0e5.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-400-normal.57844c26.woff2") format("woff2"), url("roboto-latin-400-normal.0a2ad554.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-cyrillic-ext-500-normal.216ff506.woff2") format("woff2"), url("roboto-cyrillic-ext-500-normal.4cacad2c.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-cyrillic-500-normal.4c9891c0.woff2") format("woff2"), url("roboto-cyrillic-500-normal.9867a510.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-greek-ext-500-normal.63a0634f.woff2") format("woff2"), url("roboto-greek-ext-500-normal.6ed21af2.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-greek-500-normal.9f44a402.woff2") format("woff2"), url("roboto-greek-500-normal.1ddb8d95.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-vietnamese-500-normal.11348a9c.woff2") format("woff2"), url("roboto-vietnamese-500-normal.dbc08370.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-latin-ext-500-normal.922e2b56.woff2") format("woff2"), url("roboto-latin-ext-500-normal.7f0ca47d.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-latin-500-normal.da1d77f1.woff2") format("woff2"), url("roboto-latin-500-normal.e1ea9bdc.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Public Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("public-sans-vietnamese-400-normal.1821cc1f.woff2") format("woff2"), url("public-sans-vietnamese-400-normal.c7451170.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Public Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("public-sans-latin-ext-400-normal.3294e50b.woff2") format("woff2"), url("public-sans-latin-ext-400-normal.403e2b70.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Public Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("public-sans-latin-400-normal.9fdc17f8.woff2") format("woff2"), url("public-sans-latin-400-normal.ba01bef8.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Public Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("public-sans-vietnamese-500-normal.e4973e2c.woff2") format("woff2"), url("public-sans-vietnamese-500-normal.c765354a.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Public Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("public-sans-latin-ext-500-normal.6968f5cf.woff2") format("woff2"), url("public-sans-latin-ext-500-normal.00e6dfc2.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Public Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("public-sans-latin-500-normal.44c065a1.woff2") format("woff2"), url("public-sans-latin-500-normal.3e8c42c2.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*# sourceMappingURL=index.css.map */
